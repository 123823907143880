<template>
    <b-card v-if="status" :title="`${$t('edit')}`">
        <form @submit.prevent="updateStatus" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                </b-col>
                <b-col sm="6"></b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('edit') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit') }
    },
    data: () => ({
        form: new Form({
            name: ''
        }),
        status: null
    }),
    mounted () {
        this.fetchData()
    },
    methods: {
        async updateStatus () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const id = this.$route.params.id
            await this.form.put(apiUrl + 'production-process/' + id).then(resp => {
                this.$store.dispatch('shift/fetchProductions')
                this.$router.push({ name: 'production-process' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async fetchData () {
            const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'production-process/' + id)
            this.form.fill(data.data)
            this.status = data.data
        }
    }
}
</script>
